$background-main: #222;

$ion-light-blue: #2699fb; // rgb(38, 153, 251)
$ion-med-blue: #0093ff;
$ion-dark-blue: #0472c4; // rgb(4, 114, 196)
$ion-white: #ffffff;
$ion-black: #222222;

content {
  height: 100%;
}
button,
select {
  cursor: pointer;
}

button, .edit-icons {
  background: none;
  border: none;
  outline: none;
}

// BUTTONS
%button {
  border-radius: 5px;
  height: 38px;
  text-align: center;
  cursor: pointer;
  border: none;
  line-height: 1.2;
  font-weight: bold;
  min-width: 145px;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;

  &:focus:not(.focus-visible) {
    outline: none;
  }
}

.button-primary {
  @extend %button;

  font-family: 'Roboto';
  background-color: $ion-light-blue;
  color: white;

  &:hover {
    background-color: #ffffff;
    color: $ion-light-blue;
  }

  // &:active {
  //   box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.3);
  // }

  &:active {
    background-color: $ion-white;
    box-shadow: inset 0 0 7px 1px $ion-light-blue;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}