@import '../../assets/scss/mixins.scss';

.navbar {
  @include flex(row, space-between, center);
  min-height: 100px;
  background: $ion-black;
  border-bottom: 4px solid $ion-light-blue;
  position: sticky;
  top: 0;
  z-index: 1000;
  &-banner {
    width: 200px;
    text-align: center;
    color: $ion-white;
    font-size: 40px;
  }
  
  &-items {
    width: 55%;
    margin-right: 10px;
    @include flex(row, flex-end, center);
    color: $ion-white;
    font-size: 18px;
    &-item {
      align-self: center;
      transition: .1s;
      &:hover {
        color: $ion-light-blue;
      }
      a {
        text-decoration: none;
        &:visited {
          color: unset;
        }
      }
    }

    &.signed-in {
      @include flex(row, flex-end, center);
      cursor: default;
    }
    .signed-in {
      @include flex(row, flex-end);
      margin-left: 10px;
      margin-right: 10px;
      text-align: right;
      cursor: default;
      align-self: center;
    }
  }
}