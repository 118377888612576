.restorations-container {
  position: relative;
  margin-top: 75px;
  padding-bottom: 30px;
  width: 1024px;

  &-header {
    @include flex(row, flex-start);
    align-items: center;
    margin-bottom: 64px;
    margin-left: 118px;
    // background-image: linear-gradient(to right, #2699fb 2%, rgba(255, 255, 255, 0) 20%);
    // background-position: bottom;
    // background-size: 4px 2px;
    // background-repeat: repeat-x;

    button {
      width: 148px !important;
      height: 48px;
      font-size: 16px;
    }

    .shop-logo-container {
      margin-left: 111px;
      img {
        max-width: 50%;
        max-height: 50%;
      }
      .shop-owner-image {
        height: 110px;
        width: 200px;
        background-color: #e5e5e5;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 83.8px;
          height: 83.8px;
        }
      }
    }
  }
  &-button {
    position: absolute;
    top: 30px;
    width: 150px;
    margin-left: 10%;
    padding: 15px 20px;
    background-color: $ion-light-blue;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    transition: 0.1s;

    &:hover {
      color: $ion-light-blue;
      background-color: $ion-white;
    }
    &:active {
      background-color: $ion-white;
      box-shadow: inset 0 0 7px 1px $ion-light-blue;
    }
  }
  .no-list {
    margin: 50px 120px;
    margin-top: 0px;
    padding: 35px;
    background-color: #bce0fd;
    color: $ion-light-blue;
    font-size: 14px;
    padding-bottom: 20px;

    &-header {
      font-weight: bold;
    }
    &-text {
      font-weight: normal;
    }
  }
  .no-list-container {
    padding-bottom: 10px;
    background-image: linear-gradient(
      to right,
      #2699fb 2%,
      rgba(255, 255, 255, 0) 20%
    );
    background-position: top;
    background-size: 4px 2px;
    background-repeat: repeat-x;
  }
  .triangle {
    margin-left: 18%;
    height: 0px;
    width: 0px;
    border: 20px solid #bce0fd;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid transparent;
  }
}
.restoration-list-container {
  background-image: linear-gradient(
    to right,
    #2699fb 2%,
    rgba(255, 255, 255, 0) 20%
  );
  background-position: bottom;
  background-size: 4px 2px;
  background-repeat: repeat-x;
}

.restoration {
  display: flex;
  padding: 12px 12px;
  padding-bottom: 20px;
  margin: 0px 15px;
  color: $ion-light-blue;
  // background-image: linear-gradient(
  //   to right,
  //   #2699fb 2%,
  //   rgba(255, 255, 255, 0) 20%
  // );
  // background-position: top;
  // background-size: 4px 2px;
  // background-repeat: repeat-x;
  border-top: dotted rgba(38, 153, 251, 0.3) 2px;

  &-row {
    display: flex;
    padding: 45px 0 20px 38px;

    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &.first {
        margin-right: 78px;
      }
      &.second {
        margin-right: 54px;
      }
      .header {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.63;
      }
      .info {
        // padding: 0 0px;
        line-height: 26px;
        font-size: 16px;
      }

      .button-primary-container {
        min-width: unset;

        &.button-primary {
          height: unset;
          margin: 5px 0;
        }
        button {
          font-size: 10px;
          width: 80px;
          min-width: unset;
          height: 25px;
        }
      }

      &.owner {
        display: hidden;
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &.detail-buttons {
        :first-child {
          margin-top: unset;
        }
        &:last-of-type {
          margin-left: 30px;
        }
      }
    }
  }
  img {
    max-height: 110px;
  }
  &-add-car {
    &-header {
      position: relative;
      @include flex(row, center);
      &-chevron {
        position: absolute;
        left: 72.3px;
        cursor: pointer;
      }
    }
  }
}

.restoration-details {
  margin: 52px 0 20px 0;
  padding-bottom: 67px;
  width: 1024px;
  display: flex;
  flex-direction: column;
  &-header {
    @include flex(row, center);
    position: relative;
    margin-bottom: 40px;
    &-chevron {
      position: absolute;
      left: 72.3px;
      top: 0;
      cursor: pointer;
    }
    &-title {
      font-size: 28px;
      font-weight: bold;
      line-height: 1.43;
      color: $ion-white;
    }
  }

  &-form {
    padding: 0 135px;
    &-header {
      font-size: 36px;
      font-weight: bold;
      line-height: 1.17;
      color: $ion-light-blue;
    }
    &-small-header {
      margin: 40px 0;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
      color: $ion-light-blue;
    }

    &-body {
      @include flex(row, space-between);
      .form-col {
        max-width: 326px;
      }
      .assign-car-button {
        width: 293px;
        margin: 23px 0;
        padding: 15px;
        background-color: black;
        border: 2px solid $ion-light-blue;
        border-radius: 4px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.88;
        color: $ion-white;
        transition: 0.1s;
        &:hover {
          color: $ion-white;
          background-color: $ion-light-blue;
        }
        &:active {
          background-color: $ion-light-blue;
          color: $ion-white;
          box-shadow: inset 0 0 7px 1px $ion-white;
        }
      }
      .car-details-image {
        &-container {
          width: 326px;
        }
        position: relative;
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;

        .delete-car-icon {
          position: absolute;
          top: 4px;
          left: 3px;
          cursor: pointer;
        }

        &-edit-label {
          margin-left: 25px;
          background-color: $ion-light-blue;
          color: $ion-white;
          border-radius: 6px;
          cursor: pointer;

          button {
            height: 25px;
            width: 80px;
            padding: unset;
            min-width: unset;
            font-size: 10px;
          }
        }

        &-label {
          position: absolute;
          margin-top: 25%;
          margin-left: 20px;
          padding: 10px 20px;
          background-color: $ion-light-blue;
          color: $ion-white;
          border-radius: 6px;
          cursor: pointer;
          &:hover {
            color: $ion-light-blue;
            background-color: $ion-white;
          }
        }
        .add-car-label {
          width: 200px;
          height: 110px;
          display: flex;
          justify-content: center;
          align-items: center;

          .add-with-icon {
            position: absolute;
            top: 2px;
            left: 3px;
          }
          cursor: pointer;
          &:hover {
            color: $ion-white;
          }
        }
        img {
          max-height: 200px;
          max-width: 250px;
        }
      }
      .car-details-image-populated {
      }

      .add-car-photo-container {
        width: 200px;
        height: 110px;
        background-color: #e5e5e5;
        margin-top: 0px;
      }
      .add-car {
        &-photo {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 68.8px;
          height: 68.8px;
          cursor: pointer;
        }
        &-text {
          margin-top: 8px;
          text-align: center;
          font-family: Arial;
          font-size: 13px;
          font-weight: bold;
          line-height: 1.15;
          color: #000000;
        }
      }
      #image-file {
        display: none;
      }
    }

    .company-profile-form {
      &-input-label {
        color: $ion-light-blue;
      }
      &-col-input {
        width: 295px;
        margin: 40px 0;
      }
      &-buttons {
        margin-right: 5%;
        button {
          margin-left: 5%;
        }
      }
    }
    .assign-owner-error {
      margin-top: 40px 40px;
      width: 100%;
      color: red;
    }
    .assign-owner-success {
      width: 40px;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      color: green;
    }
    &-error {
      color: red;
      text-align: center;
      margin-bottom: 40px;
    }
  }
}

.restoration-add-car {
  width: 1024px;
  margin: 0 auto;
  padding-bottom: 67px;
  &-header {
    margin: 50px 0px;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.43;
    text-align: center;
    color: $ion-white;
  }
  .restoration-details {
    &-form-header {
      margin: 0px 80px;
    }
  }
}

.missing-car-container {
  width: 200px;
  height: 110px;
  background-color: #e5e5e5;
  justify-content: center;
  display: flex;
  align-items: center;
  .missing-car-icon {
    width: 68.8px;
    height: 68.8px;
  }
}
