@import '../../assets/scss/mixins.scss';

.policy-info {
  flex-grow: 1;
  padding: 40px;
  background-color: #000;
  color: $ion-white;

  &-header {
    font-size: 42px;
    margin-top: 10px;
    text-align: center;
  }
  &-section-header {
    font-size: 28px;
  }
  &-section-sub-header {
    font-size: 20px;
  }
  p {
    margin-left: 20px;
  }
  ul {
    margin-left: 20px;
    li {
      margin: 5px;
    }
  }
}