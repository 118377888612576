@import '../../assets/scss/mixins.scss';


.landing-owner {
  @include flex(column, flex-start, stretch);
  height: 100%;
  font-size: 26px;
  background-color: rgba(28,27,27, 1);
  .landing-header {
    width: 100%;
    height: 100%;
    background: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media only screen and (max-width: 750px) {
      background-position: left -400px top 0px;
    }
  }
}
