.landing-page {
  @include flex(column, flex-start, stretch);
  height: 100%;
  font-size: 26px;
  background-color: rgba(28,27,27, 1);
}
.landing-header {
  background-color: $ion-dark-blue;
  width: 100%;
  height: 100%;
  background: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: 750px) {
    background-position: left -400px top 0px;
  }

  .hero-image {
    height:100%;
    transform: translateX(-50%);
    margin-left: 50%;
    overflow: hidden;
  }
  &-content {
    float: right;
    margin-top: 100px;
    margin-right: 10%;
    max-width: calc(100vw - 10%);
    &-icon {
      font-size: 20px;
      color: $ion-white;
    }
    &-text {
      margin-top: 35px;
      margin-bottom: 35px;
      color: $ion-white;
      & > p {
        margin: 3px 0px;
        font-size: 45px;
        font-weight: bold;
      }
    }
  }
}


@media only screen and (max-width:1024px) {
  .landing-header {
    &-content-icon {
      margin-top: -60px;
    }
    &-content-text {
      margin-top: 220px;
    }
  }
}
@media only screen and (max-width: 426px) {
  .landing-header {
    &-content-icon {
      display: none;
    }
    &-content-text {
      padding: 20px;
      padding-top: 180px;
      margin-top: 0px;
      & > p {
        font-size: 40px;
      }
    }
  }
}
