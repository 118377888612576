.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85vh;
  background-color: black;
  color: $ion-white; 
  overflow: hidden;
  &-nav {
  @include flex(row, center);
  width: 100%;
    & div:first-child {
      margin-right: 60px;
    }
    & div:nth-child(2) {
      margin-left: 60px;
    }
    &-item {
      width: 198px;
      border: 2px solid $ion-white;
      border-top: none;
      border-radius: 0px 0px 4px 4px;
      padding: 8px 18px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.88;
      cursor: pointer;

      &.active {
        background-color: $ion-white;
        color: black;
      }
    }
  }
}
