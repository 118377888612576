.slide-show {
  @include flex(column, flex-start, stretch);
  background-color: rgba(28,27,27, 1);
  height: 95%;
  .slider {
    height: 100%;
    @include flex(column, center, stretch);

  }
}
