.owner-subscription {
  height: 100%;
  color: $ion-light-blue;
  background-color: black;

  &-header {
    padding-top: 50px;
    padding-bottom: 30px;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.43;
    color: $ion-white;
    text-align: center;
  }

  &-sub-info {
    width: 30%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    border: 6px solid $ion-light-blue;
    border-radius: 20px;

    @include flex(row, center);
    background-color: $ion-white;
    color: $ion-light-blue;
    font-size: 24px;
    line-height: 1.43;

    &-text {
      padding: 10px 30px;
    }
  }
  &-stripe {
    width: 200px;
    margin: 0 auto;
    padding-bottom: 100px;
    .stripe-input {
      margin: 10px auto;
      label {
        font-size: 14px;
        line-height: 1.14;
        color: $ion-light-blue;
        .StripeElement {
          background-color: $ion-white;
          padding: 15px;
          border: solid 1px #bce0fd;
          border-radius: 4px;
          width: 85%;
          color: $ion-light-blue;
        }
        input {
          font-size: 14px;
          width: 100%;
          color: $ion-light-blue;
          letter-spacing: 1.1px;
        }
      }
      &-button {
        padding: 5px;
        background-color: $ion-light-blue;
        border: none;
        border-radius: 4px;
        font-weight: bold;
        line-height: 1.88;
        text-align: center;
        color: $ion-white;
        cursor: pointer;
        &:hover {
          background-color: $ion-white;
          color: $ion-light-blue;
        }
      }
    }
    .company-profile-form-buttons {
      width: 140%;
      transform: translateX(-10%);
      margin-top: 80px;
      &-button {
        margin-right: 5%;
      }button {
        margin-left: 5%;
      }
    }
    .error {
      color: red;
    }
    .stripe-success {
      margin-top: 40px;
      margin-bottom: -20px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: green;
    }
  }
  .cancel-success {
    text-align: center;
    font-size: 18px;
    color: green;
    font-weight: bold;
    margin-bottom: 30px;
  }
}