// For quick flexing
@mixin flex($dir, $just: flex-start, $align: stretch) {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: $dir;
	-moz-flex-direction: $dir;
	-ms-flex-direction: $dir;
	flex-direction: $dir;
	-webkit-justify-content: $just;
	-moz-justify-content: $just;
	-ms-justify-content: $just;
	-ms-flex-pack: $just;
	justify-content: $just;
	-webkit-align-items: $align;
	-moz-align-items: $align;
	-ms-align-items: $align;
	align-items: $align;
}