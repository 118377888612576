.BrainhubCarousel {
  // overflow: unset !important;

  &__container {
    // overflow: unset !important;
  }
  &__arrows {
    position: absolute !important;
    background: none !important;
    span {
      border-color: $ion-light-blue !important;
    }
  }

  &__custom-arrowLeft {
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 5px 5px 0 0;
    border-color: #2699fb;
    padding: 5px;
    transition: 0.3s;
    font-size: 0;
    left: 120px;
    transform: translate(-50%, -50%) rotate(225deg);
    margin-left: -2.45px;
    cursor: pointer;
    &:hover {
      left: 115px;
    }
  }

  &__custom-arrowRight {
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 5px 5px 0 0;
    border-color: #2699fb;
    padding: 5px;
    transition: 0.3s;
    font-size: 0;
    right: 105px;
    transform: translate(-50%, -50%) rotate(45deg);
    margin-left: -2.45px;
    cursor: pointer;
    &:hover {
      right: 100px;
    }
  }

  &__dots {
    &:before {
      background: white;
    }
    color: white;
    background: black;
  }

  .delete-car-image {
    cursor: pointer;
    position: absolute;
    top: 4px;
    left: 3px;
  }

  .car-image-loading-spinner {
    height: 110px;
    width: 110px;
  }
}

.photos {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 1024px;
  position: relative;

  &-header {
    @include flex(row, center);
    width: 100%;
    padding: 0 135px;
    margin-top: 53px;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.43;
    color: $ion-white;
    &-chevron {
      position: absolute;
      left: 72.3px;
      cursor: pointer;
    }
    &-title {
      font-size: 28px;
      font-weight: bold;
      line-height: 1.43;
      color: $ion-white;
    }
  }

  &-body {
    width: 100%;
    margin-top: 40px;
    padding: 0 0 80px;
    &-header {
      padding: 0 138px;
      margin-bottom: 48px;
      font-size: 16px;
      line-height: 1.63;
      color: $ion-light-blue;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $ion-white;
      }
    }
    &-section {
      margin: 0 auto;
      padding: 0 138px;
      position: relative;
      &-header {
        @include flex(row, space-between);
        margin-bottom: 20px;
        font-size: 20px;
        color: $ion-light-blue;
        .header-label {
          font-weight: bold;
        }
      }
    }

    .slick-slider {
      width: 80%;
      transform: translateX(10%);
    }
    .custom-class {
      outline: none;
      .yours-custom-class {
        max-width: 200px;
        max-height: 150px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    &-row {
      margin: 20px 0px;
      margin-bottom: 50px;
    }
  }
  .add-photo {
    display: flex;
    align-items: center;
    // padding-left: 31px;

    .add-car-button {
      height: 110px;
      width: 110px;
      cursor: pointer;
      margin-right: 20px;

      img {
        height: 110px;
        width: 110px;
      }
      label {
        cursor: pointer;
      }
    }
    &-no-image {
      margin: 40px 40px;
      margin-top: 0px;
      padding: 20px;
      background-color: #bce0fd;
      color: $ion-light-blue;
      font-size: 14px;
      &-title {
        font-weight: bold;
      }
      p {
        margin: 5px;
      }
    }
    .triangle {
      margin-left: 89%;
      margin-top: -20px;
      height: 0px;
      width: 0px;
      border: 20px solid #bce0fd;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid transparent;
    }
  }
}

.add-photo-input {
  display: none;
}