.create-company {
  background-color: black;
  height: 100%;
  margin-bottom: 80px;

  &-header {
    padding-top: 50px;
    padding-bottom: 36px;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.43;
    text-align: center;
    color: $ion-white;
  }
  .company-profile-image {
    & label {
      background-color: black;
      .add-logo {
        transition: .2s;
        &:hover {
          background-color: $ion-light-blue;
          & p {
            color: $ion-white;
          }
        }
      }
    }
    margin-top: 0px;

    .add-profile-logo-wrapper {
      display: flex;

      .add-profile-logo {
        transition: .2s;
        width: 200px;
        height: 110px;
        object-fit: contain;
        background-color: #e5e5e5;
        text-align: center;
      }

      .add-profile-logo-icon {
        margin-left: 14px;
        margin-top: 0;
        padding: 0;
        height: 22px;
        background-color: inherit;
        color: inherit;
        border-radius: 0;
      }

      label.edit-profile-logo-label {
        margin-top: 0;
        margin-left: 34px;
        width: 20px;
        height: 13px;
        font-family: Roboto;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: center;
        align-self: center;
        background-color: $ion-light-blue;

        &:hover {
          color: $ion-light-blue;
          background-color: $ion-white;
        }
      }
    }
  }
}
