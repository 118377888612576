@import "../shared/Modal/styles.scss";

.invite {
  max-width: 346px;
  margin: 0 auto;
  margin-top: 112px;
  padding-bottom: 96px;
  &-header {
    margin-bottom: 36px;
    color: $ion-light-blue;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;

    &-white {
      color: $ion-white;
    }
  }

  &-mechanics {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: $ion-light-blue;

    &-header {
      @include flex(row, space-between);
      &-icon {
        font-size: 10px;
        font-weight: bold;
        line-height: 1.1;
        cursor: pointer;

        &:hover {
          color: $ion-white;
          img {
            background-color: $ion-white;
            border: 1px solid $ion-white;
          }
        }
      }
      img {
        height: 15px;
        width: 15px;
        padding: 2px;
        border: 1px solid $ion-light-blue;
        border-radius: 10px;
        transform: translateX(46px);
      }
    }
  }
  &-form {
    .company-profile-form {
      &-input-label {
        font-size: 14px;
        font-weight: normal;
      }

      &-col-input-error {
        bottom: 15px;
        right: 10px;
        font-size: 16px;
        font-weight: normal;
      }
      &-buttons {
        margin-top: 70px;
        &-button {
          margin-right: 5%;
        }
        button {
          margin-left: 5%;
        }
      }
    }
    .company-profile-form-col-input {
      margin: 40px 0px;
      input {
        padding: 17px 16px 15px;
        max-width: calc(100% - 34px);
      }
    }

    .company-profile-form-buttons {
      width: 400px;
      margin-left: -28px;
    }
    &-field-group {
      position: relative;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 35px;
      background-image: linear-gradient(
        to right,
        #2699fb 12%,
        rgba(255, 255, 255, 0) 20%
      );
      background-position: bottom;
      background-size: 4px 1px;
      background-repeat: repeat-x;
      &:last-child {
        background: none;
      }
    }
    .remove-mechanic {
      position: absolute;
      top: 35px;
      right: 0px;
      height: 15px;
      width: 15px;
      transform: rotate(45deg);
      cursor: pointer;
    }
  }
}

.invite-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .check-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-header {
    margin-top: 14px;
  }

  .button-row {
    margin-top: 18px;

    button {
      height: 48px;
    }
  }
}
