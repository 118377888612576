.content {
  //flex: 1 0 auto;
  //height: 100%;
}

.no-match {
  height: 100%;
  width: 100%;
  padding-top: 80px;
  background-color: black;
  color: $ion-white;
  &-header {
    width: 300px;
    text-align: center;
    margin: 0 auto;
    font-size: 68px;
  }
  p {
    width: 400px;
    font-size: 26px;
    margin: 0 auto;
    margin-top: 100px;
  }
  .button {
    width: 200px;
    margin: 0 auto;
    padding: 15px;
    margin-top: 50px;
    background-color: $ion-light-blue;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      opacity: .7;
    }
  }
}
