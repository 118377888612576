.company-profile {
  padding-bottom: 40px;
  &-header {
    margin-top: 25px;
    margin-left: 175px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: $ion-light-blue;
  }
  &-image {
    @include flex(row, center);
    margin-top: 73px;

    label {
      margin-top: 50%;
      margin-left: 20px;
      padding: 5px 20px;
      height: 20px;
      background-color: $ion-light-blue;
      color: $ion-white;
      border-radius: 6px;
      cursor: pointer;
      transition: .1s;
      &:hover {
        color: $ion-light-blue;
        background-color: $ion-white;
      }
    }
    input#image-file {
      display: none;
    }
    .edit-icon {
      font-size: 10px;
      font-weight: bold;
      color: $ion-light-blue;
      cursor: pointer;
      transition: .15s;
      &:hover {
        color: $ion-white;
      }
    }
    .add-logo {
      height: 108px;
      min-width: 108px;
      border: solid 1px #707070;
      background-color: $ion-white;
      color: $ion-light-blue;
      border-radius: 50%;
      cursor: pointer;
      p {
        margin: 0px;
        padding-top: 0px;
        font-size: 15px;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #2699fb;
        &:first-child {
          margin-top: 30px;
        }
      }
    }

    .add-profile-logo-wrapper {
      display: flex;

      .add-profile-logo {
        transition: .2s;
        display: flex;
        background-color: #e5e5e5;
        img {
          max-height: 250px;
          object-fit: contain;
        }

        &-missing {
          background-color: #e5e5e5;
          width: 200px;
          height: 110px;
        }
      }

      .add-profile-logo-icon {
        margin-left: 14px;
        margin-top: 0;
        padding: 0;
        height: 22px;
        background-color: inherit;
        color: inherit;
        border-radius: 0;
      }

      label.edit-profile-logo-label {
        margin-top: 0;
        margin-left: 34px;
        width: 20px;
        height: 13px;
        font-family: Roboto;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: center;
        align-self: center;

        &:hover {
          color: $ion-light-blue;
          background-color: $ion-white;
        }
      }
    }
  }
  &-form {
    @include flex(row, space-between);
    margin: 0px 135px;
    color: $ion-light-blue;

    &-col {
      padding: 20px 20px;
      padding-top: 0px;
      width: 100%;
      &-input {
        position: relative;
        margin: 40px 20px;

        input {
          width: 100%;
          padding: 15px 15px;
          border-radius: 4px;
          border: solid 1px #bce0fd;
          color: $ion-light-blue;
          font-size: 14px;
        }

        label {
          position: absolute;
          top: -22px;
          font-size: 14px;
        }

        &-error {
          position: absolute;
          color: red;
          bottom: -22px;
        }
      }

      &-double-row {
        @include flex(row, space-between);
        .company-profile-form-col-input {
          width: 100%;
          margin: 10px 20px;
        }
      }
      .service-container {
        width: 125%;
        &-item {
          display: inline-block;
        }
        &-button {
          display: inline-block;
          background-color: $ion-white;
          color: $ion-light-blue;
          padding: 5px 15px;
          margin: 10px;
          border-radius: 20px;
          font-size: 12px;
          cursor: pointer;
          transition: .1s;
          &.active {
            background-color: $ion-light-blue;
            color: $ion-white;
          }
        }
      }
    }

    &-buttons {
      @include flex(row, center);
      font-size: 16px;
      font-weight: bold;
      color: $ion-white;
      text-align: center;

      &-button {
        width: 148px;
        margin-right: 2%;
        padding: 14px 10px;
        background-color: black;
        border: solid 2px $ion-light-blue;
        border-radius: 4px;
        line-height: 1.88;
        transition: .1s;
        cursor: pointer;

        &:hover {
          background-color: $ion-light-blue;
        }
        &:active {
          box-shadow: inset 0 0 7px 1px $ion-white;
        }
      }
      button {
        width: 148px;
        margin-left: 2%;
        padding: 20px;
        background-color: $ion-light-blue;
        border-radius: 4px;
        border: none;
        color: $ion-white;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          background-color: $ion-white;
          color: $ion-light-blue;
        }
        &:active {
          outline: none;
          box-shadow: inset 0 0 7px 1px $ion-light-blue;
        }
      }
    }
  }

  &-mechanics {
    height: 100%;
    margin: 20px 135px;
    padding-top: 38px;
    padding-bottom: 60px;
    background-color: black;
    background-image: linear-gradient(to right, #2699fb 12%, rgba(255, 255, 255, 0) 20%);
    background-position: top;
    background-size: 4px 1px;
    background-repeat: repeat-x;

    &-header {
      @include flex(row, space-between);
      margin-bottom: 17px;
      color: $ion-light-blue;
      font-weight: bold;

      &-title {
        font-size: 20px;
      }&-icon {
        font-size: 10px;
        text-align: left;
        cursor: pointer;
        transition: .2s;

        img {
          height: 15px;
          width: 15px;
          padding: 2px;
          border: 1px solid $ion-light-blue;
          border-radius:10px;
          transform: translateX(40px);
          transition: .2s;
        }
        &:hover {
          color: $ion-white;
          img {
            background-color: $ion-white;
            border: 1px solid $ion-white;
          }
        }
      }
    }

    &-container {
      @include flex(row, space-between, center);
      padding-bottom: 20px;
      font-size: 14px;
      color: $ion-light-blue;
      background-image: linear-gradient(to right, #2699fb 12%, rgba(255, 255, 255, 0) 20%);
      background-position: bottom;
      background-size: 4px 1px;
      background-repeat: repeat-x;

      &-info {
        line-height: 1.29;
        color: $ion-white;
        &-name {
          font-family: Roboto;
          font-size: 18px;
          font-weight: bold;
        }
        &-phone {
          font-size: 14px;
        }
        &-accepted {
          color: $ion-light-blue;
        }
      }
    }
  }
  .spinner {
    position: absolute;
    left: 50%;
  }
  &-payment-button {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 40px;
    padding: 20px;
    border: 2px solid $ion-light-blue;
    border-radius: 6px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;

    cursor: pointer;
    &:hover {
      background-color: $ion-light-blue;
    }
    &:active {
      outline: none;
      box-shadow: inset 0 0 7px 1px $ion-white;
    }
  }

  .cancel-sub {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    margin: 0 auto;
    background-color: rgba(0,0,0,.7);
    z-index: 2000;
    &-modal {
      width: 400px;
      margin: 0 auto;
      margin-top: 20vh;
      background-color: $ion-light-blue;
      color: $ion-white;
      border-radius: 6px;
      border: 6px solid $ion-light-blue;
    }
    &-header {
      padding: 20px;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      background-color: black;
      font-size: 22px;
      text-align: center;
    }
    &-buttons {
      @include flex(row, space-around);
      background-color: black;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;

      &-button {
        width: 120px;
        margin: 40px 10px;
        padding: 14px 0px;
        border: solid 2px #2699fb;
        border-radius: 4px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.88;
        transition: .1s;
        cursor: pointer;
        &:hover {
          background-color: $ion-light-blue;
        }
        &:active {
          box-shadow: inset 0 0 7px 1px $ion-white;
        }
      }
    }
  }
}
