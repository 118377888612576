@import '../../../css/ion.scss';

.navbar-button {
  margin: 0px 20px;
  padding: 5px 30px;
  color: $ion-white;
  border: 2px solid $ion-light-blue;
  border-radius: 4px;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background-color: $ion-light-blue;
  }
  &:active {
    background-color: rgba(255,255,255,.6);
  }
}

.landing-button {
  width: 150px;
  padding: 12px 0px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  transition: .15s;
  cursor: pointer;
  
  a {
    text-decoration: none;
    color: unset;
    &:visited {
      color: unset;
    }
  }

  &.landing-white {
    background-color: $ion-white;
    color: $ion-dark-blue;
    &:hover {
      background-color: $ion-dark-blue;
      color: $ion-white;
    }
    &:active {
      background-color: $ion-dark-blue;
      color: $ion-white;
      box-shadow: inset 0 0 7px 1px #fff;
    }
  }
  &.landing-blue {
    background-color: $ion-light-blue;
    color: $ion-white;
    &:hover {
      background-color: $ion-white;
      color: $ion-light-blue;
    }
    &:active {
      background-color: $ion-white;
      color: $ion-light-blue;
      box-shadow: inset 0 0 7px 1px $ion-light-blue;
    }
  }
}

.button-primary-container {
  display: inline-block;
  position: relative;
  
  label {
    position: absolute;
    z-index: 1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
  button {
    width: 100%;
  }
}
