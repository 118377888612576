.footer {
  flex-shrink: 0;
  background-color: $background-main;
  color: $ion-white;
  min-height: 100px;
  font-size: 10px;

  &-row {
    @include flex(row, space-around);
    > * {
      margin: 0px 10px;
    }
  }
  &-section {
    margin-top: 29px;
    margin-bottom: 15px;
    margin-left: -49px;
    
    .section-header {
      margin-bottom: 15px;
      line-height: 1.2;
      text-align: left;
    }
    .links {
      line-height: 2;
      text-align: left;
    }
    a {
      color: $ion-white;
      text-decoration: none;
      &:visited {
        color: $ion-white;
      }
      &:hover {
        text-decoration: underline;
      }
    }

    & .copyright {
      margin-top: 85px;
      margin-right: 25px;
      text-align: right;
    }
  }
  .social-media {
    @media only screen and (max-width: 500px) {
      margin-top: 0px;
    }

      img {
        height: 16px;
        width: 16px;
        margin-right: 20px;
      }
      @media only screen and (max-width: 500px) {
        flex-direction: column;
        justify-content: flex-start;
        > * {
          margin: 10px 0px;
        }
      }

    .app-links {
      display: flex;
      margin-top: 10px;
      @media only screen and (max-width: 500px) {
        flex-direction: column;
      }

      .google-play {
        position: relative;
        bottom: 10px;
        margin-left: -10px;
        margin-right: 10px;
        $height: 60px;
        height: $height;
        width: calc(#{$height} * 646 / 250);
      }
    }
  }
}
